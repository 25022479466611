import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { ICONS } from "../dev-icons";
// ? TYPES:
import {
  ExperienceCategoriesQuery,
  ExperienceCategoriesQuery_allWordpressCategory_edges_node,
} from "./__generated__/ExperienceCategoriesQuery";
// interface Props {}

// const SKILL_LEVELS = {
//   "Proficient Knowledge": 85,
//   "Intermediate Expertise": 72,
//   "Basic Knowledge": 60,
// };

// const IGNORE_ICONS = ["REST", "GraphQL", "Agile", "Azure", "Jira"];

type SkillPre = ExperienceCategoriesQuery_allWordpressCategory_edges_node;
// type IconType = "plain" | "line" | "original" | "original-wordmark";
interface Skill extends SkillPre {
  percentage: number;
  icon: string;
}

const Experience = (): JSX.Element => {
  const data: ExperienceCategoriesQuery = useStaticQuery(graphql`
    query ExperienceCategoriesQuery {
      allWordpressCategory(
        filter: { wordpress_parent: { eq: 0 }, wordpress_id: { ne: 1 } }
      ) {
        edges {
          node {
            id
            count
            description
            name
            slug
            wordpress_id
          }
        }
      }
    }
  `);
  const list = data.allWordpressCategory.edges
    .map((item: { node: SkillPre }) => {
      return {
        ...item.node,
        percentage: parseInt(item.node.description as string),
        icon: ICONS[item.node.name as string] || item.node.slug + "-plain",
      };
    })
    .filter((item: Skill) => !item.description!.includes("exclude")) // ignore some icons for now, maybe add svg later
    .sort((a: Skill, b: Skill) => b.percentage - a.percentage);
  return (
    <section id="experience">
      {/* <h2>
        <span>Tech Stack</span>
      </h2> */}
      <div className="skill__block">
        {/* <div className="skill__title">
          <h3>{group.type}</h3>
        </div> */}
        <ul>
          {list.map((skill, i) => {
            return (
              <li
                key={skill.id}
                className="skill"
                data-aos="flip-left"
                data-aos-anchor="#experience"
                data-aos-delay={i * 50}
                data-aos-offset="-190"
              >
                <div>
                  <i className={`devicon-${skill.icon}`}></i>
                </div>
                <div>
                  <span className="skill-name">{skill.name}</span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default Experience;
