import React from "react";
import Img from "gatsby-image";
import GIFImage from "./gif-image";
import Video from "./video";
// ? TYPES:
import { FeaturedMedia } from "../../models/featured-media.model";
import { CSSProperties } from "react";

const FluidImage = ({
  featuredMedia,
  style = {},
  imgStyle = {},
  empty = null,
}: {
  featuredMedia: FeaturedMedia | null;
  imgStyle?: CSSProperties;
  style?: CSSProperties;
  empty?: null | JSX.Element;
}): JSX.Element | null => {
  if (!featuredMedia || !featuredMedia.localFile) {
    return empty;
  }
  if (featuredMedia.mime_type === "video/mp4") {
    return (
      <Video featuredMedia={featuredMedia} imgStyle={imgStyle} style={style} />
    );
  }
  if (featuredMedia.mime_type === "image/gif") {
    return <GIFImage featuredMedia={featuredMedia} imgStyle={imgStyle} />;
  }
  return (
    <Img
      style={style}
      fadeIn={true}
      fluid={featuredMedia.localFile.childImageSharp.fluid}
      imgStyle={imgStyle}
    />
  );
};

export default FluidImage;
