import React from "react";
// ? TYPES:
import { FeaturedMedia } from "../../models/featured-media.model";
import { CSSProperties } from "react";

const Video = ({
  featuredMedia,
  imgStyle = {},
  style = {},
}: {
  featuredMedia: FeaturedMedia;
  style?: CSSProperties;
  imgStyle?: CSSProperties;
  empty?: null | JSX.Element;
}): JSX.Element | null => {
  return (
    <div className="work__img--wrapper gatsby-image-wrapper" style={style}>
      <div
        aria-hidden="true"
        style={{
          width: "100%",
          paddingBottom: "56.25%",
        }}
      />
      <video autoPlay muted loop playsInline style={imgStyle}>
        <source src={featuredMedia.source_url} type={featuredMedia.mime_type} />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Video;
