import React, { useState, useEffect } from "react";
// import { Link } from "gatsby";
import useScrollPosition from "../hooks/use-scroll-position";
import Layout from "../components/layout";
import MetaHeader from "../components/meta-header";
import Experience from "../components/experience";
import LatestWork from "../components/latest-work";
// import Image from "../components/image";
import SEO from "../components/seo";

// import angleDownArrow from "../images/icons/angle-down-arrow.svg";

const IndexPage = () => {
  const [lowered, setLowered] = useState<boolean>(true);
  useScrollPosition(({ prevPos, currPos }) => {
    const direction = prevPos.y > currPos.y ? "UP" : "DOWN";
    if (direction === "UP" && currPos.y === 0) {
      setLowered(true);
    }
    if (direction === "DOWN" && currPos.y >= 100) {
      setLowered(false);
    }
    // console.log(direction, prevPos, currPos);
  }, []);
  return (
    <Layout slug="home">
      <SEO title="Home" />
      <MetaHeader lowered={lowered} />
      <LatestWork />
      <Experience />
      {/* <div style={{ maxWidth: `300px` }}>
        <Image />
      </div>
      <Link to="/page-2/">Go to page 2</Link> */}
      <a
        href="#meta-header"
        id="scrollIcon"
        className="bounce"
        // onClick={() => setLowered(false)}
        style={{ display: (!lowered && "none") || undefined }}
      >
        {/* <img className="bounce" src={angleDownArrow} alt="scroll down" /> */}
        <div className="arrow" />
      </a>
    </Layout>
  );
};

export default IndexPage;
