import { useRef, useLayoutEffect } from "react";

const isBrowser = typeof window !== "undefined";

interface Position {
  x: number;
  y: number;
}

function getScrollPosition({
  element,
  useWindow,
}: {
  element?: any;
  useWindow: boolean;
}): Position {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element ? element.current : document.body;
  const position = target.getBoundingClientRect();

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top };
}

function useScrollPosition(
  effect: ({
    prevPos,
    currPos,
  }: {
    prevPos: Position;
    currPos: Position;
  }) => void,
  deps: any[] = [],
  element?: any, // HTML Element?
  useWindow = true,
  wait?: number
): void {
  const position = useRef(getScrollPosition({ useWindow }));

  let throttleTimeout: ReturnType<typeof setTimeout> | null = null;

  const callBack = () => {
    const currPos: Position = getScrollPosition({ element, useWindow });
    effect({ prevPos: position.current, currPos });
    position.current = currPos;
    throttleTimeout = null;
  };

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait);
        }
      } else {
        callBack();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return (): void => window.removeEventListener("scroll", handleScroll);
  }, deps);
}

export default useScrollPosition;
