import React from "react";
import { useStaticQuery, graphql } from "gatsby";
// import Masonry from "./masonry/index";
// import { Link } from "gatsby";
import FluidImage from "./image/fluid-image";
// import FixedImage from "./image/fixed-image";
// import emptyFolderIcon from "../images/empty-folder.svg";
import EmptyPic from "../images/svg/other-work-pic.svg";
// ? TYPES:
import { WordpressPosts_allFile_edges_node } from "./__generated__/WordpressPosts";
import { Post } from "../models/post.model";
import { FeaturedMedia } from "../models/featured-media.model";
// interface Props {}

const getFeaturedMedia = (
  featuredMedia: FeaturedMedia | null,
  allFiles: { node: WordpressPosts_allFile_edges_node }[]
): FeaturedMedia | null => {
  if (!featuredMedia) {
    return null;
  }
  if (featuredMedia.mime_type === "image/gif") {
    const f = allFiles.find(a => a.node.name === featuredMedia.slug);
    if (f) {
      return {
        ...featuredMedia,
        source_url: f.node.publicURL,
        mime_type: f.node.internal.mediaType,
      } as FeaturedMedia;
    }
    return featuredMedia;
  }
  return featuredMedia;
};

const LatestWork = (): JSX.Element => {
  const q = useStaticQuery(graphql`
    query WordpressPosts {
      allWordpressPost(sort: { fields: [date], order: DESC }) {
        edges {
          node {
            id
            content
            excerpt
            path
            slug
            status
            sticky
            title
            wordpress_id
            featured_media {
              alt_text
              caption
              date
              id
              media_type
              mime_type
              path
              post
              slug
              source_url
              title
              wordpress_id
              localFile {
                childImageSharp {
                  # Try editing the "maxWidth" value to generate resized images.
                  fluid(maxWidth: 960, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                  #fixed(width: 960, height: 540, quality: 100) {
                  #  ...GatsbyImageSharpFixed_withWebp_noBase64
                  #}
                  #fixed(width: 96, height: 54, quality: 100) {
                  #  ...GatsbyImageSharpFixed_withWebp_noBase64
                  #}
                }
              }
            }
            categories {
              description
              name
            }
          }
        }
      }
      allFile(filter: { extension: { eq: "mp4" } }) {
        edges {
          node {
            publicURL
            name
            internal {
              mediaType
            }
            relativePath
            relativeDirectory
            extension
          }
        }
      }
    }
  `);
  const edges: { node: Post }[] = q.allWordpressPost.edges;
  const list = [
    ...edges,
    {
      node: {
        id: "empty",
        content: "<p>Future endeavors just around the corner.</p>",
        excerpt: "",
        // excerpt: "<p>Around The Corner</p>",
        path: "",
        slug: "",
        status: "",
        sticky: false,
        title: "More Projects",
        wordpress_id: -1,
        featured_media: null,
        categories: [],
      },
    },
  ].map(post => ({
    node: {
      ...post.node,
      categories: post.node.categories.filter(
        category => category.description !== ""
      ),
    },
  }));
  return (
    <section id="latest-work" style={{ marginBottom: "6.4rem" }}>
      {/* <h2>Latest work</h2> */}
      <div className="work__block">
        {list.map(({ node }) => {
          const links =
            node.content.match(
              /(<a class=\"wp-block-button__link\s*).*?(\s*<\/a>)/gs
            ) || [];
          const featuredMedia = getFeaturedMedia(
            node.featured_media,
            q.allFile.edges
          );
          return (
            <div
              className="work__item"
              data-aos="zoom-in-up"
              data-aos-offset="-100"
              key={node.id}
            >
              <div className="work__meta">
                <div className="work__content">
                  <h3 dangerouslySetInnerHTML={{ __html: node.title }}></h3>
                  <div
                    className="work__description"
                    dangerouslySetInnerHTML={{ __html: node.content }}
                  ></div>
                  <footer className="work__tags">
                    {node.categories.map(c => (
                      <span className="work__tag" key={c.name}>
                        {c.name}
                      </span>
                    ))}
                  </footer>
                </div>
                <div className="work__img">
                  <FluidImage
                    style={{ height: "100%" }}
                    imgStyle={{ objectFit: "cover" }}
                    featuredMedia={featuredMedia}
                    empty={
                      <div className="work__img--empty gatsby-image-wrapper">
                        <div
                          aria-hidden="true"
                          style={{
                            width: "100%",
                            paddingBottom: "56.25%",
                          }}
                        />
                        <EmptyPic />
                        {/* <img aria-hidden="true" src={emptyPic} alt="" /> */}
                      </div>
                    }
                  />
                </div>
                <footer className="work__img__footer">
                  {links.map((link: string) => (
                    <span
                      className="work__link"
                      key={link}
                      dangerouslySetInnerHTML={{ __html: link }}
                    />
                  ))}
                </footer>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default LatestWork;
