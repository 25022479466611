// import { Link } from "gatsby";
import React from "react";
import { useStaticQuery, graphql } from "gatsby";

interface Props {
  lowered: boolean;
}

const MetaHeader = ({ lowered }: Props): JSX.Element => {
  const data = useStaticQuery(graphql`
    query AuthorQuery {
      site {
        siteMetadata {
          author
          greeting
        }
      }
      homePage: wordpressPage(slug: { eq: "home" }) {
        id
        slug
        content
      }
      author: wordpressWpUsers(
        authored_wordpress__PAGE: { elemMatch: { slug: { eq: "home" } } }
      ) {
        name
        description
      }
    }
  `);
  const author = data.author.name; //data.site.siteMetadata.author;
  const greeting = data.author.description;
  const summary = data.homePage.content;
  return (
    <header id="meta-header" className={lowered ? "lowered" : ""}>
      <div id="meta-greeting">
        <h1>
          <span className="color-blue">{greeting} </span>
          <span id="meta-author">{author}</span>
        </h1>
      </div>
      <div
        id="meta-summary"
        dangerouslySetInnerHTML={{ __html: summary }}
      ></div>
    </header>
  );
};

export default MetaHeader;
